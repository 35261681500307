
.navbar{
  min-height: 8vh;
  //background: linear-gradient(-45deg, #4f7dc0, #1d4ac5);
  background: linear-gradient(-45deg, #1c0c77d0, #1c0c77ec);
  box-shadow: 0 6.4px 14.4px 0 rgb(0 0 0 / 13%), 0 1.2px 3.6px 0 rgb(0 0 0 / 11%) ;

}

nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;

}

nav ul li {
    float: left;

    display: list-item;
    text-align: -webkit-match-parent;
}


nav ul li a {
    display: inline-block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}

nav ul li a:hover{
    background-color: #f3f3f3;
    transition: 0.5s;
    text-decoration: none;
}


.nav-dropdown {
  position: static;
  display: block;
}

.nav-dropdown .active{
  display: none;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 10;
  border-radius: 2%;
}

.nav-dropdown:hover .dropdown-content {
  display: block;
}



@media screen and (max-width: 930px) {

    nav ul li  {
       display: none;
    }
    
    .nav-dropdown .active {
        display: block;
    }
    .dropdown-content {
        right: 0;
    }
    .nav-menu {
        display: block;
    }

  }
