* {
  --colorRed : red;
}

$darkColor: dark;
$primaryColor: skyblue;
$secondaryColor:blue ;
$greyColor: grey;
$successColor: green;
$dangerColor: red;


.text-error {
  color : $dangerColor;
  position: absolute;
  font-weight: bold;
  z-index: 10;
  transition: all .5s linear;
  transition: 3s;
}
