// .autocomplete {
//     width: 12.5em;

//     border: solid 1px #8b8b8b;
// }

// @media only screen and (max-width: 750px) {
//     .autocomplete {
//         width: 94%;
//     }
// }


.wrapper {
  margin: 0 auto;
  background: #ffffff;
  border-radius: 5px;
  position: relative;
  //width: 200px;
  //box-shadow: 0px 1px 5px 3px rgba(0,0,0,0.15);
  border-radius: 3px;
}

.wrapper input {
  width: 100%;
  border: none;
  border: solid 1px rgba(177, 177, 177, 0.815);
  height: 45px;
  border-radius: 4px;
  padding: 0px 10px 0px 10px;
  font-size: 18px;
}

.wrapper input:focus {
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.select-input {  
  width: 100%;
  border: none;
  border: solid 1px rgba(177, 177, 177, 0.815);
  height: 45px;
  border-radius: 4px;
  padding: 0px 30px 0px 30px;
  font-size: 18px;
  background-color: white;
  text-align: center;
}

.select-input:focus {
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.results {
  width: 100%;
  padding: 0px;
  position: absolute;
  z-index: 100;
  max-height: 50vh;
  background-color: white;
  //overflow-y: auto;
  overflow-y:visible;
  overflow-X: hidden;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.results ul {
  margin: 0;
  padding: 0;
  overflow-X: hide;
}

.results ul li {
  list-style: none;
  //opacity: 0;
  //display: none;
  padding: 8px 12px;
  //transition: all .5s linear;
  border-bottom: solid 0.1px rgba(216, 216, 216, 0.404);
}

.show .results ul li {
  opacity: 1;
  display: block;
}

.show .results {
  padding: 10px;
}

.results ul li:hover {
  background: #ececec;
  cursor: pointer ;
}

.history-list {
  max-width: 350px;
  position: absolute;
  right: 0;
  top: 30px;
  padding: 0px;
  z-index: 100;
  max-height: 50vh;
  background-color: white;
  overflow-y: auto;
  overflow-X: hidden;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
// button {
//   position: absolute;
//   border: none;
//   background: none;
//   top: 0;
//   right: 0;
//   height: 50px;
//   line-height: 50px;
//   padding: 0;
//   width: 50px;
// }

// button:focus {
//   outline: none;
// }

// button i {
//   width: 100%;
//   color: #6d7b98;
//   line-height: 50px;
//   font-size: 22px;
// }

.custom-btn-remove {
  position: absolute;
  right: -5px;
  top: -5px;
}

.custom-btn-icon {
  background-color: transparent;
  border: none;

}

.custom-btn-remove-icon {
  color: lightgrey;
}