
.arrow-top-scroll-top {
    position: fixed;
    top: 90%;
    left: 80%;
}
/* .removeQ {
    cursor: pointer;
    color: black;
    background-color: #5976c7;
    border-radius: 50%;
    padding: 3px;
  }
  
  .removeQ:hover {
    color: white;
    background-color: rgb(186, 187, 189);
  } */

.home-title-container{
  width: 100%;
  text-align: center;
  padding-top: 15vh;
}

.home-title {
  font-size: 60px;
}

.bloc-decor-left {
  top: 12vh;
  width: 100%;
  position: absolute;
  z-index: -2;
}
.bloc-decor-right {
  top: 17vh;
  width: 100%;
  position: absolute;
  // display: flex;
  // flex-direction: column;
  // flex-direction: row-reverse;
  z-index: -2;
}
 
.dc-decor-left {
  background: linear-gradient(-45deg, #1c0c7757, #1c0c77ec);
  height: 20px;
  margin-top: 20px;
  z-index: -2;

}
.dc-decor-right {
  background: linear-gradient(-45deg, #1c0c77ec, #1c0c7757);
  height: 20px;
  margin-top: 20px;
  margin-left: auto;
  z-index: -2;
}

#decor-left-first {
  width: 25%;
}
#decor-left-second {
  width: 12%;
}
#decor-left-third {
  width: 9%;
}
#decor-left-fourth {
  width: 6%;
}

#decor-right-first {
  width: 6%;
}
#decor-right-second {
  width: 10%;
}
#decor-right-third {
  width: 12%;
}
#decor-right-fourth {
  width: 9%;
}