#queryResume {
    width: 98%;
    border: solid 1px rgb(206, 206, 206);
    border-radius: 5px;
    padding: 2%;
    margin-top: 2%;

}

.query {
    border-radius: 30px;
    background-color: #616161;
    padding: 0.4em;
    color: antiquewhite;
    margin: 2px;
    cursor: pointer;
}

.query:hover {
    background-color: #616161c0;
    //padding: 0.45em;
    transition: 0.1s;
}


.div-querys-add {
    max-height: 40vh;
    overflow-y: auto;
    overflow-X: hidden;
}
