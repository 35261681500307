.modalCustom-page {
    top: 0px;
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(219, 219, 219, 0.404);
    z-index: 10;
}

.modalCustom {
    position: fixed;
    background-color: white;
    top: 5%;
    left: 10%;
    position: fixed;
    width: 80%;
    height: 80%;
    border-radius: 1%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.modalCustom-medium {
    position: fixed;
    background-color: white;
    top: 5%;
    left: 25%;
    position: fixed;
    width: 50%;
    height: 80%;
    border-radius: 1%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

@media (max-width: 840px) {
    .modalCustom-medium {
        top: 10%;
        left: 2.5%;
        width: 95%;
    }
  }

.modalCustom-body {
    padding: 1%;
    overflow-y: auto;
    width: 100%;
    height: 70vh;
}

.querys-modal-history{
    max-height: 50px;
}

.custom-pos-notif {
    position: absolute;
    
}